/*
 * @Author: 周晴龙
 * @Date: 2020-08-14 15:31:24
 * @LastEditors: 周晴龙
 * @LastEditTime: 2020-08-14 15:54:37
 * @FilePath: \user\src\api\documentManagement\index.js
 * @Description:
 */
import DocumentService from "./DocumentService";

export const documentService = new DocumentService();
