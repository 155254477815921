/*
 * @Author: wuqi
 * @Date: 2020-12-17 14:27:33
 * @LastEditors: wuqi
 * @LastEditTime: 2021-07-09 16:27:44
 * @Description:
 */
import { dealStreamResp, downFile } from "@/tools/util";
import BaseService from "../BaseService";

class DownFileApi extends BaseService {
  constructor(http) {
    super("", http);
  }

  async batchUpLoadFileZip(params) {
    const { http } = this;
    const res = await http.post("batchUpLoadFileZip", { ...params, requestType: "stream" }, {
      responseType: "arraybuffer"
    });

    const dealResp = dealStreamResp(res);
    if (dealResp.cType === "stream") {
      downFile(dealResp.blob, decodeURI(dealResp.fileName));
    } else {
      throw Error("没有找到文件");
    }

    return res;
  }
}

export default DownFileApi;
