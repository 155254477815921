<template>
  <div class="docs-admin-wrap">
    <card-header :title="menuData.name" @editTheme='editTheme'  :menuData='menuData' >
      <template #right>
        <div>
          <en-icon v-if="edit" class="header-right-arrow" name="shanchu-danchuang" size="14px" @click.native="deleteData"></en-icon>
          <en-icon v-else @click.native="toDocSlist" class="header-right-arrow" name="zuocecaidanlan-shouqi" size="22px"></en-icon>
        </div>
      </template>
    </card-header>
    <div class="equal-main">
      <en-table :data="tableData" max-height="100%">
        <!-- <en-table-column type="index" width="60"></en-table-column> -->
        <en-table-column title="文档名称1" prop="name">
          <template slot-scope="scope">
            <div class="file-name">
              <en-icon name="fujian-tongyong" size="small" color="#3e90fe"></en-icon>
              <span>{{ scope.row.name }}</span>
              <en-icon name="guding-wendangguanli" size="12px" color="#49bef2" style="margin-left:10px" v-if="scope.row.top === '000'"></en-icon>
            </div>
          </template>
        </en-table-column>
        <en-table-column title="发布者" field="deptName" width="25%"> </en-table-column>
        <en-table-column title="发布时间" field="modifyTime" width="25%">
          <template slot-scope="scope">
            {{ scope.row.modifyTime | formatDate }}
          </template>
        </en-table-column>
        <!-- <en-table-column title="概要信息" field="msg"></en-table-column> -->
        <en-table-column title="附件" field="attr" width="20%">
          <template slot-scope="scope">
            <div class="appendix-btn" :loading="downing" @click="multipleDownload(scope.row)">
              <en-icon name="fujianhetupian-kongjian" size="16px"></en-icon>({{ scope.row.attr.length }})
            </div>
          </template>
        </en-table-column>
      </en-table>
    </div>
  </div>
</template>

<script>
import { documentService } from "@/api/documentManagement";
import { downFileApi } from "@/api/downFileApi";
import CardHeader from "./card-header";
// 附件弹窗
export default {
  name: "DocsAdmin",
  props: {
    menuData: {
      type: Object
    },
    edit: {
      default: false
    }
  },
  components: {
    CardHeader
  },
  filters: {
    formatDate(value) {
      const date = new Date(value);
      const y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? `0${MM}` : MM;
      let d = date.getDate();
      d = d < 10 ? `0${d}` : d;
      let h = date.getHours();
      h = h < 10 ? `0${h}` : h;
      let m = date.getMinutes();
      m = m < 10 ? `0${m}` : m;
      let s = date.getSeconds();
      s = s < 10 ? `0${s}` : s;
      return `${y}-${MM}-${d} ${h}:${m}:${s}`;
    }
  },
  data() {
    return {
      downing: false,
      loading: false,
      // 是否为编辑分类
      // 左侧菜单tree
      // 分页
      pageModel: {
        pageSize: 10,
        pageNo: 1,
        total: null,
        totalPages: null
      },
      // 文件列表
      docInfo: [],
      tableData: [],
      buttonData: [
        {
          name: "编辑",
          icon: "bianji-liebiao",
          iconColor: "#26c393"
        },
        {
          name: "置顶",
          icon: "zhiding-wendangguanli",
          iconColor: "#3e90fe"
        },
        {
          name: "删除",
          icon: "shibai",
          iconColor: "#f76b6b"
        }
      ],
      classifyItem: {} // 编辑分类数据
    };
  },
  mounted() {
    this.handleSearch();
  },
  methods: {
      deleteData() {
        this.$emit("deleteData");
      },
    editTheme() {
        this.$emit("editTheme");
    },
    toDocSlist() {
      this.$router.push({
        path: "/document-management/home"
      });
    },
    // 搜索
    handleSearch() {
      this.queryDocList("", 1, this.pageModel.pageSize, this.searchkey);
    },
    /**
     * @description:多选下载
     * @param {type}
     * @return:
     */
    async multipleDownload(data) {
      try {
        this.downing = true;
        await downFileApi.batchUpLoadFileZip({ id: data.id, type: 1 });
        this.$message({
          type: "success",
          message: "下载成功！"
        });
        this.downing = false;
      } catch (error) {
        this.downing = false;
      }
    },
    // 查询文档列表
    async queryDocList(id, pageNo = 1, pageSize = 10) {
      try {
        this.loading = true;
        const res = await documentService.queryRules({
          classifyId: id,
          pageNo,
          pageSize
        });
        this.tableData = res.records;
        this.$nextTick(() => {
          console.log(this.$refs.ourtable);
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    }
  },
  activated() {
    this.handleSearch();
  }
};
</script>

<style lang="scss" scoped>
.docs-admin-wrap {
  height: 100%;
  .header-right-arrow {
    // width:20px;
    // height: 20px;
  }
  .equal-main {
    position: relative;
    height: calc(100% - (20px + 30px)) !important;
    clear: both;
    .en-icon {
      display: inline-block;
      vertical-align: middle;
      margin-top: -2px;
    }
  }
  .file-name {
    span {
      margin-left: 5px;
    }
  }
  .appendix-btn {
    cursor: pointer;
    color: #3e90fe;
  }
  /deep/ .el-tree {
    .el-tree-node__label {
      font-size: 12px;
    }
  }
}
</style>
