/*
 * @Author: 周晴龙
 * @Date: 2020-08-14 15:32:34
 * @LastEditors: zhulin
 * @LastEditTime: 2021-07-15 16:19:03
 * @FilePath: \user\src\api\documentManagement\DocumentService.js
 * @Description:
 */
import BaseService from "../BaseService";
/**
 * login相关
 */
class DocumentService extends BaseService {
  pk = "";

  constructor(http) {
    super("", http);
  }

  /**
   * @description  查询文档管理左侧菜单
   * @param params 登录参数
   * @returns {*}
   */
  queryRulesClassify(params) {
    const { http } = this;
    return http.post("queryRulesClassify", params);
  }

  /**
   * @description: 查询文档列表
   * @param params 登录参数
   * @return {*}
   */
  queryRules(params) {
    const { http } = this;
    return http.post("queryRules", params);
  }

  /**
   * @description: 文档日志
   * @param params
   * @return {*}
   */
   saveFileLog(params) {
    return this.http.post("saveFileLog", params);
  }

  /**
   * @description:文件置顶
   * @param params 登录参数
   * @return {*}
   */
  topRules(params) {
    const { http } = this;
    return http.post("topRules", params);
  }

  /**
   * @description:所属上级树
   * @param params 登录参数
   * @return {*}
   */
  queryRulesClassifyTree(params) {
    const { http } = this;
    return http.post("queryRulesClassifyTree", params);
  }

  /**
   * @description:新增分类信息
   * @param params 登录参数
   * @return {*}
   */
  addRulesClassify(params) {
    const { http } = this;
    return http.post("addRulesClassify", params);
  }

  /**
   * @description: 获取部门列表树
   * @param {*} params
   * @return {*}
   */
  queryDeptTreeAll(params) {
    const { http } = this;
    return http.post("queryDeptTreeAll", params);
  }

  /**
   * @description: 新增文档
   * @param {*} params
   * @return {*}
   */
  addRulesDocs(params) {
    const { http } = this;
    return http.post("addRules", params);
  }

  /**
   * @description: 修改文档
   * @param {*} params
   * @return {*}
   */
  editRulesDocs(params) {
    const { http } = this;
    return http.post("editRules", params);
  }

  /**
   * @description: 删除文档
   * @param {*} params
   * @return {*}
   */
  deleteRules(params) {
    const { http } = this;
    return http.post("deleteRules", params);
  }

  /**
   * @description: 修改文档分类
   * @param {*} params
   * @return {*}
   */
  editRulesClassify(params) {
    const { http } = this;
    return http.post("editRulesClassify", params);
  }

  /**
   * @description: 删除分类
   * @param {*} params
   * @return {*}
   */
  deleteRulesClassify(params) {
    const { http } = this;
    return http.post("deleteRulesClassify", params);
  }

  /**
   * @description: 查询按钮权限
   * @param {*} params
   * @return {*}
   */
   queryRulessAccess(params) {
    const { http } = this;
    return http.post("queryRulessAccess", params);
  }
}
export default DocumentService;
