/*
 * @Author: wuqi
 * @Date: 2020-12-17 14:26:47
 * @LastEditors: wuqi
 * @LastEditTime: 2020-12-17 14:43:17
 * @Description:
 */
import DownFileApi from "./DownFileApi";

export const downFileApi = new DownFileApi();
